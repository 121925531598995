.bg-light {
  background-color: #b5d3ff !important;
}

.dark-mode {
    background-color: #1a202c;
    color: #e2e8f0;
  }
  
  .dark-mode .bg-light {
    background-color: #2d3748 !important;
  }
  
  .dark-mode .text-muted-foreground {
    color: #a0aec0;
  }
  
  .dark-mode .bg-primary-light {
    background-color: #2c5282;
  }
  
  .dark-mode .custom-btn {
    background-color: #4a5568;
    color: #e2e8f0;
  }
  
  .dark-mode .form-control {
    background-color: #2d3748;
    color: #e2e8f0;
    border-color: #4a5568;
  }
  
  .dark-mode #newsletter {
    background-color: #2c5282;
  }